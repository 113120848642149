li.page-item:last-child .page-link
{
    border-radius:0px!important;
    width:100px!important;
}
li.page-item:first-child .page-link
{
    border-radius:0px!important;
    width:100px!important;
}
div[data-test="datatable-info"]
{
    padding-top:15px;
}
div[data-test="datatable-pagination"]
{
    padding-top:15px;
}
div[data-test="datatable-select"] label
{ 
     text-align:center;
     padding-bottom:0px;
}

div[data-test="datatable-select"] label select
{
    width:70px;
    margin-top:5px;
}

#mdbdttableexport div[data-test="datatable-select"] label select
{
    width:70px;
    margin-top:5px;
}

#mdbdttableexport .dataTables_length{
    display: none;
}

#mdbdttableexport .mdb-datatable-filter{
    display: none;
}



#mdbdttableexport .dataTables_info{
    display: none;
}

#mdbdttableexport .dataTables_paginate{
    display: none;
}

#mdbdttableexport thead[data-test="datatable-head"]{
    display: none;
}

#mdbdttableexport thead[data-test="table-foot"]{
    display: none;
}

#test-table-xls-button{
    background-color: #2DCE89;
}

#Stoixeia{
    text-align: center;
}

#mdbdttableexport td{
    white-space: break-spaces;
}

#rae-logo{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    border: 1;
    border-style: groove;
}